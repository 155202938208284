<template>
  <div v-if="chatRoomInfo.chatRoomId" class="chatroom-wrapper">
    <div v-if="recipientDetail" class="recipient-header-container">
      <div class="recipient-header-wrapper">
        <div class="profile-img-container">
          <img :src="recipientDetail.displayPhotoFilePath ?? defaultProfilePic" />
        </div>
        <div class="fs-20 fw-700">{{ recipientDetail.userName }}</div>
      </div>
    </div>

    <div class="messages-container">
      <div v-if="messages.length > 0" class="messages-wrapper">
        <div
          v-for="message in messages"
          :key="message"
          class="message"
          :class="message.createdBy.toLowerCase() == ownUserId.toLowerCase() ? 'own' : 'other'"
        >
          <div class="message-content-wrapper">
            <div class="content">{{ message.content }}</div>
            <div class="datetime">{{ dayjs(message.createdDate).format('DD/MM/YYYY h:mm a') }}</div>
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <el-input
          class="input-field"
          placeholder="Type something"
          v-model="message"
          @keydown.enter="sendMessage"
        ></el-input>
        <div class="send-btn" @click="sendMessage">
          <span class="material-icons">send</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import chatServices from '@/services/chat-service';
import profileServices from '@/services/profile-service';
import { getAuthID } from '@/helpers';
import { useSignalR } from '@quangdao/vue-signalr';
import dayjs from 'dayjs';
import { useStore } from 'vuex';

export default {
  props: {
    chatRoomInfo: {
      type: Object,
      default() {
        return {
          chatRoomId: null,
        };
      },
    },
    updateChatRooms: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const signalR = useSignalR();
    const messages = ref([]);
    const recipientDetail = ref(null);
    const defaultProfilePic = process.env.VUE_APP_DEFAULT_PIC_URL;
    const ownUserId = getAuthID().userId;
    const message = ref('');
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);

    const scrollToBottom = () => {
      // No need scroll to bottom if user not yet select chatroom
      if (props.chatRoomInfo.chatRoomId) {
        setTimeout(() => {
          document.getElementsByClassName('messages-container')[0].scrollTop = document.getElementsByClassName('messages-container')[0].scrollHeight;
        }, 1);
      }
    };

    const pushSignalRBodyToMessage = (body) => {
      // if (body.chatRoomId === props.chatRoomInfo.chatRoomId) {
      //   messages.value.push({
      //     createdBy: body.senderUserId,
      //     content: body.message,
      //   });
      //   scrollToBottom();
      // }
      messages.value.push({
        createdBy: body.senderUserId,
        content: body.message,
      });
      scrollToBottom();
    };

    const initailChatRoom = (chatRoomId) => {
      chatServices.retrieveChatRoomMessages(chatRoomId, 0).then((data) => {
        messages.value = data;
        // always scrool to bottom
        scrollToBottom();
      });
      profileServices.getProfilebyUserId(props.chatRoomInfo.recipient).then((data) => {
        recipientDetail.value = {
          ...data,
          displayPhotoFilePath: data.displayPhotoFilePath ?? defaultProfilePic,
        };
      });
    };

    onMounted(async () => {
      signalR.on('ReceiveMessage', (body) => {
        pushSignalRBodyToMessage(body);
      });

      if (isMobileView.value) {
        initailChatRoom(props.chatRoomInfo.chatRoomId);
      }
    });

    watch(
      () => props.chatRoomInfo,
      async (newChat) => {
        initailChatRoom(newChat.chatRoomId);
      },
    );

    const sendMessage = () => {
      const messageBody = {
        senderUserId: ownUserId,
        recipientUserId: props.chatRoomInfo.recipient,
        message: message.value,
      };

      chatServices.sendMessage(messageBody).then(() => {
        props.updateChatRooms();
        pushSignalRBodyToMessage(messageBody);
      });
      message.value = '';
    };

    return {
      messages,
      recipientDetail,
      defaultProfilePic,
      ownUserId,
      message,
      sendMessage,
      dayjs,
    };
  },
};
</script>
