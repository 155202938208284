<template>
  <div class="chat" :style="{ height: `calc(100vh - ${headerFooterheight}px)` }">
    <div class="chatrooms-list-container" :style="{ width: isMobileView ? '100%' : '30%' }">
      <div
        v-for="chatRoom in chatRooms"
        :key="chatRoom.chatRoomId"
        @click="chatRoomPreviewOnClick(chatRoom)"
      >
        <ChatRoomPreview
          :isSelected="selectedChatRoom.chatRoomId === chatRoom.chatRoomId"
          :chatRoomInfo="chatRoom"
        />
      </div>
    </div>
    <div class="chatroom-container" v-if="!isMobileView">
      <ChatRoom :chatRoomInfo="selectedChatRoom" :updateChatRooms="getChatRooms" />
    </div>
  </div>

  <el-drawer
    size="100%"
    :withHeader="false"
    :show-close="false"
    v-model="mobileChatDrawer"
    direction="btt"
    modal-class="mobile-chatroom-container"
    :append-to-body="false"
    :destroy-on-close="true"
  >
    <div class="header">
      <div class="primary-header">
        <div class="header-name-wrapper" @click="chatRoomPreviewOnClose">
          <div class="icon-wrapper d-flex" >
            <span class="xm-el-icon-s-left"></span>
            <br />
          </div>
        </div>
        <div style="display: flex; align-items: center;">
          <el-image
            class="circle-image cursor-pointer img-size-35"
            :src="loginProfile.displayPhotoFilePath"
            fit="fill"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="chatroom-container" v-if="mobileChatDrawer">
      <ChatRoom :chatRoomInfo="selectedChatRoom" :updateChatRooms="getChatRooms" />
    </div>
  </el-drawer>
</template>

<script>
import {
  ref, onMounted, onBeforeMount, onBeforeUnmount, computed,
} from 'vue';
import { useSignalR } from '@quangdao/vue-signalr';
import { getAuthID } from '@/helpers';
import chatServices from '@/services/chat-service';
import ChatRoomPreview from '@/components/Chat/ChatRoomPreview.vue';
import ChatRoom from '@/components/Chat/ChatRoom.vue';
import { useStore } from 'vuex';

export default {
  components: {
    ChatRoomPreview,
    ChatRoom,
  },
  setup() {
    const signalR = useSignalR();
    const { userId } = getAuthID();
    const chatRooms = ref([]);
    const messages = ref([]);
    const headerFooterheight = ref(0);
    const selectedChatRoom = ref({
    });
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);
    const loginProfile = computed(() => store.state.auth.loginProfile);
    const mobileChatDrawer = ref(false);

    // Hide footer before component mount
    // Add display none class in footer
    onBeforeMount(() => {
      document.getElementsByClassName('el-footer')[0].classList.add('d-none');
    });

    const getChatRooms = async () => {
      chatRooms.value = await chatServices.retrieveChatRoom(userId);
    };

    onMounted(async () => {
      // Listen to receiving message event from SignalR hub
      signalR.on('ReceiveMessage', async () => {
        getChatRooms();
      });

      getChatRooms();
      headerFooterheight.value = document.getElementsByClassName('header-section')[0].getBoundingClientRect().height;
    });

    // Show footer before component unmount
    // Remove display none class in footer
    onBeforeUnmount(() => {
      document.getElementsByClassName('el-footer')[0].classList.remove('d-none');
    });

    const chatRoomPreviewOnClick = (chatRoom) => {
      selectedChatRoom.value = chatRoom;

      if (isMobileView.value) {
        mobileChatDrawer.value = true;
      }
    };

    const chatRoomPreviewOnClose = () => {
      mobileChatDrawer.value = false;
      selectedChatRoom.value = {
      };
    };

    return {
      getChatRooms,
      chatRooms,
      selectedChatRoom,
      messages,
      headerFooterheight,
      isMobileView,
      mobileChatDrawer,
      chatRoomPreviewOnClick,
      chatRoomPreviewOnClose,
      loginProfile,
    };
  },
};
</script>
