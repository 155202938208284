<template>
  <div class="chatroom-preview-container" :class="isSelected ? 'selected' : ''" v-if="recipientDetail">
    <div class="profile-img-container">
      <img :src="recipientDetail.displayPhotoFilePath ?? defaultProfilePic" />
    </div>
    <div class="content-container">
      <div style="padding-left: 20px;">
        <div class="fw-700">{{ recipientDetail.userName }}</div>
        <div
          class="message-preview"
        >{{ chatRoomInfo.lastMessage ? chatRoomInfo.lastMessage.content : '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import profileServices from '@/services/profile-service';

export default {
  props: {
    chatRoomInfo: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      defaul: false,
    },
  },
  setup(props) {
    const recipientDetail = ref(null);
    const defaultProfilePic = process.env.VUE_APP_DEFAULT_PIC_URL;

    onMounted(async () => {
      recipientDetail.value = await profileServices.getProfilebyUserId(props.chatRoomInfo.recipient);
    });

    return {
      recipientDetail,
      defaultProfilePic,
    };
  },
};
</script>
